import React, {useEffect} from 'react';
import './ComingSoonPage.scss'; // Make sure to create and link this CSS file

function ComingSoonPage() {
    useEffect(() => {
        document.title = 'Adonis';
      }, []);

    return (
        <div className="coming-soon-page">
            <div className="logo">
                <img src="/logo.jpg" alt="Logo" />
            </div>
            <p>COMING SOON</p>
        </div>
    );
}

export default ComingSoonPage;
