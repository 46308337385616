import React from 'react';
import './App.css';
// import Header from './components/Header/Header.jsx';
// import HomePage from './pages/HomePage/HomePage.jsx';
// import Footer from './components/Footer/Footer';
import ComingSoonPage from './pages/ComingSoon/ComingSoonPage';


function App() {
    return (
        <div className="App">
            {/* <Header />
            <HomePage />
            <Footer /> */}
            <ComingSoonPage />
        </div>
    );
}

export default App;
